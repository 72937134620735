import { CustomFields } from '@air/api';
import { CustomFieldColor, CustomFieldListResponse } from '@air/api/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CustomFieldModalFormData } from '~/components/CustomFields/CustomFieldModal/CustomFieldModalForm/types';
import {
  getConfigViewOptionsKey,
  getCustomFieldOptionsKey,
  getWorkspaceCustomFieldsKey,
} from '~/constants/react-query-keys';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { currentViewTypeNameSelector } from '~/store/configViews/selectors';
import { useCanSeePrivateIPTCFields } from '~/swr-hooks/workspaces/addOns/useCanSeePrivateIPTCFields';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { useAirStore } from '~/utils/ReduxUtils';

import { editCustomFieldsInCache } from './utils';

export const useEditWorkspaceCustomField = () => {
  const queryClient = useQueryClient();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;
  const store = useAirStore();

  const { canSeePrivateIPTCFields } = useCanSeePrivateIPTCFields();

  const mutation = useMutation({
    mutationFn: ({
      formValues,
      id,
      colors = [],
    }: {
      formValues: CustomFieldModalFormData;
      id: string;
      colors: Array<CustomFieldColor> | undefined;
    }) => {
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      return CustomFields.updateCustomField({
        workspaceId,
        customField: {
          id,
          description: formValues.description,
          name: formValues.name,
          values: formValues.values?.map((v) => {
            const currentColor = colors.find((c) => c.backgroundHex === v.color.backgroundHex);
            return {
              id: v.isNew ? '' : v.id,
              color: {
                id: currentColor!.id,
                backgroundHex: v.color.backgroundHex,
                fontHex: v.color.fontHex,
                name: currentColor!.name,
              },
              value: v.value,
            };
          }),
        },
      });
    },

    // When mutate is called:
    onMutate: async ({ formValues, id }) => {
      if (!currentWorkspace?.id) {
        return;
      }

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: getWorkspaceCustomFieldsKey(currentWorkspace.id) });

      // Snapshot the previous value
      const previousCustomFields = queryClient.getQueryData<CustomFieldListResponse>(
        getWorkspaceCustomFieldsKey(currentWorkspace.id),
      );

      // Optimistically update to the new value
      queryClient.setQueryData<CustomFieldListResponse | undefined>(
        getWorkspaceCustomFieldsKey(currentWorkspace.id),
        (customFields) => {
          return editCustomFieldsInCache({
            existingCustomFields: customFields,
            newCustomField: {
              description: formValues.description,
              id,
              name: formValues.name,
            },
          });
        },
      );

      // Return a context object with the snapshotted value
      return { previousCustomFields };
    },
    onSuccess: (_data, { id }) => {
      if (!currentWorkspace?.id) {
        return;
      }

      queryClient.invalidateQueries({ queryKey: getCustomFieldOptionsKey(id) });

      const viewType = currentViewTypeNameSelector(store.getState());

      queryClient.invalidateQueries({
        queryKey: getConfigViewOptionsKey({
          workspaceId: currentWorkspace.id,
          viewType,
          canSeeIPTCFields: canSeePrivateIPTCFields,
        }),
      });
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (error, _newTodo, context) => {
      if (!currentWorkspace?.id) {
        return;
      }

      reportErrorToBugsnag({
        error,
        context: 'Failed to delete custom field',
      });

      queryClient.setQueryData(getWorkspaceCustomFieldsKey(currentWorkspace.id), context?.previousCustomFields);
    },
  });

  return { editCustomField: mutation };
};
