import { AirActionTrackingLocation, useTrackClickedSaveCustomField } from '@air/analytics';
import { BaseCustomField } from '@air/api/types';
import { useEffect } from 'react';

import { stopEscKeyPropagationOnAssetDetailsModal } from '~/components/AssetModal/shared/hooks/useAssetModalEscKeyHandler';
import { CustomFieldModal } from '~/components/CustomFields/CustomFieldModal/CustomFieldModal';
import { CustomFieldModalFormData } from '~/components/CustomFields/CustomFieldModal/CustomFieldModalForm/types';
import { useDeleteWorkspaceCustomField } from '~/swr-hooks/customFields/useDeleteWorkspaceCustomField';
import { useEditWorkspaceCustomField } from '~/swr-hooks/customFields/useEditWorkspaceCustomField';
import { useGetCustomFieldColors } from '~/swr-hooks/customFields/useGetCustomFieldColors';
import { useGetCustomFieldTypes } from '~/swr-hooks/customFields/useGetFieldTypes';
import { useGetPrivateCustomFieldOptions } from '~/swr-hooks/customFields/useGetPrivateCustomFieldOptions';
import { selectableCustomFields } from '~/swr-hooks/customFields/utils';
import { useGetPrivateConfigViewOptions } from '~/swr-hooks/gallery/viewFields/useGetPrivateConfigViewsOptions';

export interface CustomFieldsEditModalProps extends AirModalProps {
  field: BaseCustomField;
  trackingLocation: AirActionTrackingLocation;
  onSaveChanges?: () => void;
  onDelete?: () => void;
}

export const CustomFieldsEditModal = ({
  onClose,
  field,
  trackingLocation,
  onSaveChanges,
  onDelete,
}: CustomFieldsEditModalProps) => {
  const { data: colors } = useGetCustomFieldColors();
  const { data: fieldTypes } = useGetCustomFieldTypes();
  const needsCfOptions = selectableCustomFields.includes(field.type.title);
  const { data: cfOptions } = useGetPrivateCustomFieldOptions({
    customFieldId: field.id,
    enabled: !!needsCfOptions,
  });
  const { refetch: refetchConfigViews } = useGetPrivateConfigViewOptions();
  const { deleteCustomField } = useDeleteWorkspaceCustomField();
  const { trackClickedSaveCustomField } = useTrackClickedSaveCustomField();
  const { editCustomField } = useEditWorkspaceCustomField();

  useEffect(() => {
    stopEscKeyPropagationOnAssetDetailsModal(true);
    return () => {
      stopEscKeyPropagationOnAssetDetailsModal(false);
    };
  }, []);

  return (
    <CustomFieldModal
      modalType="edit"
      fieldTypes={fieldTypes?.data}
      isLoading={editCustomField.isPending || deleteCustomField.isPending}
      handleDelete={() =>
        deleteCustomField.mutateAsync(
          {
            customFieldId: field.id,
            trackingLocation,
          },
          {
            onSuccess: () => {
              onDelete?.();
              onClose();
            },
          },
        )
      }
      handleSubmit={async (formValues: CustomFieldModalFormData) => {
        editCustomField.mutate(
          {
            formValues,
            id: field.id,
            colors: colors?.data,
          },
          {
            onSuccess: () => {
              trackClickedSaveCustomField({ location: trackingLocation });
              refetchConfigViews();
              onSaveChanges?.();
              onClose();
            },
          },
        );
      }}
      initialFormValues={{
        description: field.description ?? '',
        fieldTypeId: field.type.id,
        id: field.id,
        name: field.name,
        values: cfOptions?.data || [],
      }}
      onClose={onClose}
      colors={colors?.data || []}
      isDataLoading={needsCfOptions && !cfOptions}
    />
  );
};
