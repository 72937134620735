import { matchesAirror, NO_WORKSPACE_PERMISSION } from '@air/errors';
import Router from 'next/router';
import { useEffect } from 'react';

import { Routes } from '~/constants/routes';

export const useHandleIfNoWorkspacePermissionError = (error: unknown) => {
  useEffect(() => {
    Router.ready(() => {
      if (matchesAirror(error, NO_WORKSPACE_PERMISSION)) {
        Router.push(Routes.noWorkspacePermission);
      }
    });
  }, [error]);
};
