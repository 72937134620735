import { AirActionTrackingLocation, useTrackClickedDeleteCustomField } from '@air/analytics';
import { CustomFields } from '@air/api';
import { CustomFieldListResponse } from '@air/api/types';
import { useToasts } from '@air/provider-toast';
import { reportErrorToBugsnag } from '@air/utils-error';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  ASSETS_CUSTOM_FIELDS,
  BOARD_CUSTOM_FIELDS,
  getConfigViewOptionsKey,
  getWorkspaceCustomFieldsKey,
} from '~/constants/react-query-keys';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { currentViewTypeNameSelector } from '~/store/configViews/selectors';
import { useCanSeePrivateIPTCFields } from '~/swr-hooks/workspaces/addOns/useCanSeePrivateIPTCFields';
import { createEmptyListResponseStructure } from '~/utils/ApiUtils';
import { useAirStore } from '~/utils/ReduxUtils';

export const useDeleteWorkspaceCustomField = () => {
  const queryClient = useQueryClient();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;
  const { trackClickedDeleteCustomField } = useTrackClickedDeleteCustomField();
  const { showToast } = useToasts();
  const store = useAirStore();

  const { canSeePrivateIPTCFields } = useCanSeePrivateIPTCFields();

  const mutation = useMutation({
    mutationFn: ({ customFieldId }: { customFieldId: string; trackingLocation: AirActionTrackingLocation }) => {
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return CustomFields.deleteCustomField({ workspaceId, customFieldId });
    },

    // When mutate is called:`
    onMutate: async ({ customFieldId }) => {
      if (!currentWorkspace?.id) {
        return;
      }

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: getWorkspaceCustomFieldsKey(currentWorkspace.id) });

      // Snapshot the previous value
      const previousCustomFields = queryClient.getQueryData<CustomFieldListResponse>(
        getWorkspaceCustomFieldsKey(currentWorkspace.id),
      );

      // invalidate assets and custom fields to make sure they are refetched on asset/board page
      queryClient.invalidateQueries({
        queryKey: [BOARD_CUSTOM_FIELDS],
      });
      queryClient.invalidateQueries({
        queryKey: [ASSETS_CUSTOM_FIELDS],
      });

      // Optimistically update to the new value
      queryClient.setQueryData<CustomFieldListResponse>(getWorkspaceCustomFieldsKey(currentWorkspace.id), (cache) =>
        cache
          ? {
              ...cache,
              data: cache.data.filter(({ id }) => id !== customFieldId),
            }
          : createEmptyListResponseStructure(),
      );

      // Return a context object with the snapshotted value
      return { previousCustomFields };
    },
    onSuccess: (_data, { trackingLocation }) => {
      if (!currentWorkspace?.id) {
        return;
      }

      trackClickedDeleteCustomField({ location: trackingLocation });

      const viewType = currentViewTypeNameSelector(store.getState());

      queryClient.invalidateQueries({
        queryKey: getConfigViewOptionsKey({
          workspaceId: currentWorkspace.id,
          viewType,
          canSeeIPTCFields: canSeePrivateIPTCFields,
        }),
      });

      showToast('Custom field deleted.');
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (error, _newTodo, context) => {
      if (!currentWorkspace?.id) {
        return;
      }

      reportErrorToBugsnag({
        error,
        context: 'Failed to delete custom field',
      });

      showToast('Failed to delete custom field. Please try again.');
      queryClient.setQueryData(getWorkspaceCustomFieldsKey(currentWorkspace.id), context?.previousCustomFields);
    },
  });

  return { deleteCustomField: mutation };
};
